'use client';
import React, { useImperativeHandle, useState } from 'react';
import { VariantProps } from 'class-variance-authority';
import { Primitive } from '@radix-ui/react-primitive';
import {
  ResponsiveDialog,
  ResponsiveDialogClose,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogFooter,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
} from '../responsive-dialog/responsive-dialog';
import { Button, buttonVariants } from '../button/button';

export type ConfirmationProps = {
  closeLabel: string;
  confirmationLabel: string;
  title: string;
  onConfirm: () => Promise<void> | void;
  description?: React.ReactNode;
  children?: React.ReactNode;
  variant?: VariantProps<typeof buttonVariants>['variant'];
};

export interface ConfirmationRef {
  open: () => void;
  close: () => void;
}

export const Confirmation = React.forwardRef<ConfirmationRef, ConfirmationProps>(function Confirmation(
  {
    closeLabel,
    confirmationLabel,
    title,
    onConfirm,
    description,
    children,
    variant = 'destructive',
  }: ConfirmationProps,
  ref,
): React.JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function onClick(): Promise<void> {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  }

  useImperativeHandle(ref, () => ({
    open: (): void => setOpen(true),
    close: (): void => setOpen(false),
  }));

  return (
    <>
      <Primitive.button
        type="button"
        aria-expanded={open}
        data-state={open ? 'open' : 'closed'}
        onClick={() => setOpen(!open)}
        asChild
      >
        {children}
      </Primitive.button>
      <ResponsiveDialog open={open} onOpenChange={setOpen}>
        <ResponsiveDialogContent className="gap-md flex flex-col">
          <ResponsiveDialogHeader className="gap-sm flex flex-col">
            <ResponsiveDialogTitle className="leading-md text-lg font-bold">{title}</ResponsiveDialogTitle>
            <ResponsiveDialogDescription className="text-secondary">{description}</ResponsiveDialogDescription>
          </ResponsiveDialogHeader>
          <ResponsiveDialogFooter>
            <ResponsiveDialogClose asChild>
              <Button data-testid="close-modal-button" type="button" className="w-full" variant="secondary">
                {closeLabel}
              </Button>
            </ResponsiveDialogClose>
            <ResponsiveDialogClose asChild>
              <Button
                data-testid="confirm-modal-button"
                onClick={onClick}
                className="w-full"
                variant={variant}
                loading={isLoading}
              >
                {confirmationLabel}
              </Button>
            </ResponsiveDialogClose>
          </ResponsiveDialogFooter>
        </ResponsiveDialogContent>
      </ResponsiveDialog>
    </>
  );
});
