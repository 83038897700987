import React from 'react';
import { cn } from '@utils';
import { ChevronDown } from 'lucide-react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../accordion/accordion';
import { Avatar, AvatarFallback, AvatarImage } from '../avatar/avatar';
import { Popover, PopoverContent, PopoverTrigger } from '../popover/popover';

export type AvatarMenuProps = {
  avatarImageUrl?: string;
  avatarFallback: React.ReactNode;
  children: React.ReactNode;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  className?: string;
  variant?: 'popover' | 'accordion';
  portalContainer?: HTMLElement;
};

export const AvatarMenu = (props: AvatarMenuProps): React.JSX.Element => {
  const { variant = 'accordion', ...rest } = props;

  if (variant === 'popover') {
    return <PopoverAvatarMenu {...rest} />;
  }

  const { avatarImageUrl, avatarFallback, title, subTitle, className, children } = rest;

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="avatar-menu" className={className}>
        <AccordionTrigger className="hover:bg-secondary-background p-xs w-full rounded-sm hover:no-underline">
          <div className="flex flex-1 items-center overflow-hidden uppercase" data-testid="avatar-menu">
            <Avatar className="size-xxl mr-xs flex-shrink">
              {avatarImageUrl ? <AvatarImage src={avatarImageUrl} alt="avatar image" /> : null}
              <AvatarFallback className="bg-accent text-foreground">{avatarFallback}</AvatarFallback>
            </Avatar>
            <span className="flex w-full flex-1 flex-col overflow-hidden text-left">
              <span className="font-highlight truncate font-semibold">{title}</span>
              <span className="font-highlight leading-sm text-subtext truncate text-sm">{subTitle}</span>
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-xs pb-xs w-full">{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
AvatarMenu.displayName = 'AvatarMenu';

export type PopoverAvatarMenuProps = AvatarMenuProps;

export const PopoverAvatarMenu = ({
  avatarImageUrl,
  avatarFallback,
  title,
  subTitle,
  className,
  children,
  portalContainer,
}: PopoverAvatarMenuProps): React.JSX.Element => {
  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          'hover:border-primary focusable w-full border border-transparent [&[data-state=open]_svg.lucide-chevron-down]:rotate-180',
          className,
        )}
      >
        <div className="flex flex-1 items-center overflow-hidden uppercase" data-testid="avatar-menu-popover">
          <Avatar className="size-xxl flex-shrink">
            {avatarImageUrl ? <AvatarImage src={avatarImageUrl} alt="avatar image" /> : null}
            <AvatarFallback className="bg-accent text-foreground">{avatarFallback}</AvatarFallback>
          </Avatar>
          <span className="ml-xs hidden w-full flex-1 flex-col overflow-hidden text-left sm:flex">
            <span className="font-highlight truncate font-semibold">{title}</span>
            <span className="font-highlight leading-sm text-subtext truncate text-sm">{subTitle}</span>
          </span>
          <ChevronDown className="ml-xs size-md hidden shrink-0 transition-transform duration-200 sm:block" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="p-xs leading-sm border-primary-border w-[160px] !min-w-[200px] sm:w-[var(--radix-popover-trigger-width)]"
        portalContainer={portalContainer}
        align="end"
      >
        {children}
      </PopoverContent>
    </Popover>
  );
};
PopoverAvatarMenu.displayName = 'PopoverAvatarMenu';
