'use client';
import { useCallback, useState } from 'react';
import { LogOut, UserRound } from 'lucide-react';
import { Primitive } from '@radix-ui/react-primitive';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { cn } from '@utils';
import {
  ResponsiveDialog,
  ResponsiveDialogClose,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogFooter,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
} from '../responsive-dialog/responsive-dialog';
import { Button } from '../button/button';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarSeparator,
  SidebarTrigger,
} from '../sidebar/sidebar';
import { Topbar, TopbarLeft, TopbarRight } from '../sidebar/topbar';

type LogoutCtaProps = {
  logoutUrl: string;
  logoutWarningText: string;
  logoutConfirmText: string;
  logoutCancelText: string;
  children: React.ReactNode;
};

function LogoutConfirm({
  logoutUrl,
  logoutWarningText,
  logoutConfirmText,
  logoutCancelText,
  children,
}: LogoutCtaProps): React.JSX.Element {
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const onClickLogout = useCallback(async (): Promise<void> => {
    setIsLoggingOut(true);
    window.location.replace(logoutUrl);
  }, [logoutUrl]);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Primitive.button
        type="button"
        aria-expanded={isOpen}
        data-state={isOpen ? 'open' : 'closed'}
        onClick={() => setIsOpen(!isOpen)}
        asChild
      >
        {children}
      </Primitive.button>
      <ResponsiveDialog open={isOpen} onOpenChange={setIsOpen}>
        <ResponsiveDialogContent className="flex flex-col items-center justify-center">
          <ResponsiveDialogHeader>
            <ResponsiveDialogDescription />
            <ResponsiveDialogTitle>{logoutWarningText}</ResponsiveDialogTitle>
          </ResponsiveDialogHeader>
          <ResponsiveDialogFooter>
            <ResponsiveDialogClose asChild>
              <Button type="button" className="w-full" variant="secondary">
                {logoutCancelText}
              </Button>
            </ResponsiveDialogClose>
            <Button
              onClick={onClickLogout}
              className="w-full"
              variant="destructive"
              data-testid="confirm-logout"
              loading={isLoggingOut}
            >
              {logoutConfirmText}
            </Button>
          </ResponsiveDialogFooter>
        </ResponsiveDialogContent>
      </ResponsiveDialog>
    </>
  );
}

export type AuthenticatedLayoutProps = {
  AvatarMenu: React.FC<{ children: React.ReactNode; variant?: 'popover' | 'accordion' }>;
  logo?: React.ReactNode;
  profileText?: string;
  profilePath?: string;
  logoutText?: string;
  sidebarContent?: React.ReactNode;
  sidebarFooter?: React.ReactNode;
  showSidebarFooterSeparator?: boolean;
  portalContainer?: HTMLElement;
  className?: string;
  children: React.ReactNode;
} & Partial<LogoutCtaProps>;

export const AuthenticatedLayout = ({
  logo,
  AvatarMenu,
  profileText,
  profilePath,
  logoutText,
  sidebarContent,
  sidebarFooter,
  showSidebarFooterSeparator = true,
  portalContainer,
  className,
  children,
  ...props
}: AuthenticatedLayoutProps): React.JSX.Element => {
  const pathname = usePathname();

  const AvatarMenuContent = (
    <SidebarMenu className="text-subtext">
      {profilePath && profileText ? (
        <SidebarMenuItem>
          <SidebarMenuButton asChild isActive={profilePath === pathname}>
            <Link href={profilePath}>
              <UserRound />
              <span>{profileText}</span>
            </Link>
          </SidebarMenuButton>
        </SidebarMenuItem>
      ) : null}
      {logoutText && props.logoutUrl && props.logoutWarningText && props.logoutConfirmText && props.logoutCancelText ? (
        <SidebarMenuItem>
          <LogoutConfirm
            logoutUrl={props.logoutUrl}
            logoutWarningText={props.logoutWarningText}
            logoutConfirmText={props.logoutConfirmText}
            logoutCancelText={props.logoutCancelText}
          >
            <SidebarMenuButton className="text-error-primary" data-testid="logout-button">
              <LogOut />
              <span>{logoutText}</span>
            </SidebarMenuButton>
          </LogoutConfirm>
        </SidebarMenuItem>
      ) : null}
    </SidebarMenu>
  );

  const SidebarContentNode = (
    <>
      <SidebarHeader>
        {logo ? <div className="mb-md h-xxxl flex w-full justify-center">{logo}</div> : null}
        <div className="desktop-only-block">
          <AvatarMenu>{AvatarMenuContent}</AvatarMenu>
        </div>
      </SidebarHeader>
      {sidebarContent ? (
        <>
          <SidebarSeparator />
          <SidebarContent>{sidebarContent}</SidebarContent>
        </>
      ) : null}
      {sidebarFooter ? (
        <>
          {showSidebarFooterSeparator && <SidebarSeparator />}
          <SidebarFooter>{sidebarFooter}</SidebarFooter>
        </>
      ) : null}
    </>
  );

  return (
    <>
      <main className="flex w-full flex-col md:flex-row">
        <SidebarProvider isMobile className="mobile-only-flex h-fit min-h-fit">
          <Sidebar side="right" collapsible="offcanvas" variant="floating" portalContainer={portalContainer}>
            {SidebarContentNode}
          </Sidebar>
          <Topbar className="h-fit">
            {logo ? (
              <TopbarLeft>
                <div className="h-xl">{logo}</div>
              </TopbarLeft>
            ) : null}
            <TopbarRight>
              <div className="gap-md flex flex-row items-center">
                <AvatarMenu variant="popover">{AvatarMenuContent}</AvatarMenu>
                <div>
                  <SidebarTrigger />
                </div>
              </div>
            </TopbarRight>
          </Topbar>
        </SidebarProvider>
        <SidebarProvider className="desktop-only-flex w-fit">
          <Sidebar side="left" collapsible="none" variant="sidebar" portalContainer={portalContainer}>
            {SidebarContentNode}
          </Sidebar>
        </SidebarProvider>
        <div className={cn('flex flex-1 flex-col items-center overflow-y-auto', 'p-md desktop:p-xxl', className)}>
          <div className="desktop:max-w-[1212px] w-full">{children}</div>
        </div>
      </main>
    </>
  );
};
AuthenticatedLayout.displayName = 'AuthenticatedLayout';
