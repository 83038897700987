import { format } from 'date-fns';
import { getMatchingLocale } from './date';

export const localizedTimeFrame = (
  start: Date,
  end: Date,
  locale: string,
): { day: string; startingAt: string; endingAt: string } => {
  return {
    day: format(new Date(start), 'MMM d'),
    startingAt: localizedDate(start, locale),
    endingAt: localizedDate(end, locale),
  };
};

export const localizedDate = (date: Date, locale: string): string => {
  const dateLocale = getMatchingLocale(locale);
  if (dateLocale === undefined) {
    throw new Error('No matching locale was found');
  }
  return new Date(date).toLocaleTimeString(dateLocale.code, { timeStyle: 'short' });
};
