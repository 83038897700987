import { format, formatDistance, Locale, transpose } from 'date-fns';
import { tz, TZDate } from '@date-fns/tz';
import * as DateFnsLocales from 'date-fns/locale';

export enum FORMAT_PATTERN {
  DATE_FORMAT_COMPACT = 'P',
  DATE_FORMAT = 'PPP',
  DATE_FORMAT_SHORT_MONTH = 'PP',
  DATE_FORMAT_WITH_WEEK_DAY = 'PPPP',
  DATE_FORMAT_WITH_SHORT_WEEK_DAY = 'EEE d MMM yyyy',
  DATETIME_FORMAT = 'PPp',
  TIME = 'HH:mm',
  MONTH = 'LLLL',
  DATETIME_PICKER_INPUT = 'yyyy-MM-dd HH:mm',
  DATE_PICKER_INPUT = 'yyyy-MM-dd',
}

export function getMatchingLocale(locale: string): Locale | undefined {
  if (locale === 'en') {
    return DateFnsLocales.enIN;
  }
  if (locale.includes('-')) {
    return DateFnsLocales[locale.replace('-', '') as keyof typeof DateFnsLocales];
  }
  return DateFnsLocales[locale as keyof typeof DateFnsLocales];
}

export function formatToHumanReadable(
  date: Date,
  locale: string,
  formatPattern: FORMAT_PATTERN,
  timezone?: string,
): string {
  const matchingLocale = getMatchingLocale(locale);
  if (matchingLocale === undefined) {
    throw new Error('No matching locale was found');
  }

  return timezone
    ? format(new TZDate(date, timezone), formatPattern, { locale: matchingLocale })
    : format(date, formatPattern, { locale: matchingLocale });
}

export function formatFromNow(date: Date, locale: string, options: { addSuffix?: boolean } = {}): string {
  const matchingLocale = getMatchingLocale(locale);
  if (matchingLocale === undefined) {
    throw new Error('No matching locale was found');
  }

  return formatDistance(date, new Date(), {
    locale: matchingLocale,
    addSuffix: true,
    ...options,
  });
}

/**
 * Apply timezone to local date-time.
 * For instance, 09:45+01:00 becomes 09:45+09:00 if target timezone is Asia/Tokyo (+09:00) and locale tz is Berlin (+01:00).
 * @param localDateTime local date-time
 * @param targetTimezone target timezone
 */
export function replaceTimezone(localDateTime: Date, targetTimezone: string): Date {
  return new Date(transpose(localDateTime, tz(targetTimezone)).toISOString());
}

/**
 * Replace timezone of input date-time with locale timezone, so that it can be displayed as if it were in input timezone.
 * For instance, 09:45+09:00 becomes 09:45+01:00 if input timezone is Asia/Tokyo (+09:00) and locale tz is Berlin (+01:00).
 * @param dateTime input date-time
 * @param timezone input timezone
 */
export function replaceTimezoneWithLocale(dateTime: Date, timezone: string): Date {
  const newDate = new TZDate(dateTime.toISOString(), timezone);
  return new Date(format(newDate, 'yyyy-MM-dd HH:mm'));
}
