'use client';

import { useMemo } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { customTranslate, escapeKey } from '../services/i18n.services';

export type TranslationFunction = ReturnType<typeof useTranslations>;

export const useClientTranslations = (namespace?: string): { t: TranslationFunction; locale: string } => {
  const t = useTranslations(namespace ? escapeKey(namespace) : undefined);
  const locale = useLocale();

  return useMemo(
    () => ({
      t: Object.assign(customTranslate(t), t),
      locale,
    }),
    [t, locale],
  );
};
