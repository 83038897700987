import * as React from 'react';

const MOBILE_BREAKPOINT = 1056;

/**
 * Check if we're on mobile or on desktop
 * WARNING: THIS HOOK ALWAYS RETURNS FALSE BEFORE HYDRATION
 * Use it only behind a useEffect or a onClick!
 *
 * @returns true if the viewport width is below 1056 pixels
 */

export function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT - 1}px)`);
    const onChange = (): void => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    };
    mql.addEventListener('change', onChange);
    setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    return (): void => mql.removeEventListener('change', onChange);
  }, []);

  return !!isMobile;
}
