'use client';
import React, { useMemo } from 'react';
import { useIsMobile } from '../../hooks/use-mobile';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../dialog/dialog';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '../drawer/drawer';

type ResponsiveDialogContext = { isMobile: boolean };
const ResponsiveDialogContext = React.createContext<ResponsiveDialogContext | null>(null);

type Component = React.FC<{ className?: string; children?: React.ReactNode; asChild?: boolean }>;
function createResponsiveDialogComponent({
  DialogComponent,
  DrawerComponent,
  name,
}: {
  DialogComponent: Component;
  DrawerComponent: Component;
  name: string;
}): Component {
  const ResponsiveDialogComponent: Component = ({ children, className, ...props }) => {
    const context = React.useContext(ResponsiveDialogContext);
    const isMobile = context?.isMobile;
    return isMobile ? (
      <DrawerComponent className={className} {...props}>
        {children}
      </DrawerComponent>
    ) : (
      <DialogComponent className={className} {...props}>
        {children}
      </DialogComponent>
    );
  };
  ResponsiveDialogComponent.displayName = name;
  return ResponsiveDialogComponent;
}

export const ResponsiveDialogContent = createResponsiveDialogComponent({
  DialogComponent: DialogContent,
  DrawerComponent: DrawerContent,
  name: 'ResponsiveDialogContent',
});
export const ResponsiveDialogHeader = createResponsiveDialogComponent({
  DialogComponent: DialogHeader,
  DrawerComponent: DrawerHeader,
  name: 'ResponsiveDialogHeader',
});
export const ResponsiveDialogTitle = createResponsiveDialogComponent({
  DialogComponent: DialogTitle,
  DrawerComponent: DrawerTitle,
  name: 'ResponsiveDialogTitle',
});
export const ResponsiveDialogDescription = createResponsiveDialogComponent({
  DialogComponent: DialogDescription,
  DrawerComponent: DrawerDescription,
  name: 'ResponsiveDialogDescription',
});
export const ResponsiveDialogFooter = createResponsiveDialogComponent({
  DialogComponent: DialogFooter,
  DrawerComponent: DrawerFooter,
  name: 'ResponsiveDialogFooter',
});
export const ResponsiveDialogClose = createResponsiveDialogComponent({
  DialogComponent: DialogClose,
  DrawerComponent: DrawerClose,
  name: 'ResponsiveDialogClose',
});

function ResponsiveDialogInternal({
  children,
  onOpenChange,
}: {
  children: React.ReactNode;
  onOpenChange: (open: boolean) => void;
}): React.JSX.Element {
  // Use this hook only once for the dialog/drawer and all its children
  const isMobile = useIsMobile();
  const contextValue = useMemo<ResponsiveDialogContext>(() => ({ isMobile }), [isMobile]);

  return (
    <ResponsiveDialogContext.Provider value={contextValue}>
      {isMobile ? (
        <Drawer open onOpenChange={onOpenChange}>
          {children}
        </Drawer>
      ) : (
        <Dialog open onOpenChange={onOpenChange}>
          {children}
        </Dialog>
      )}
    </ResponsiveDialogContext.Provider>
  );
}

export type ResponsiveDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
};

export function ResponsiveDialog({ open, onOpenChange, children }: ResponsiveDialogProps): React.JSX.Element | null {
  // Put the dialog/drawer in the DOM only when it's open
  if (!open) return null;
  return <ResponsiveDialogInternal onOpenChange={onOpenChange}>{children}</ResponsiveDialogInternal>;
}
